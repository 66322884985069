<script setup lang="ts">
import { ref, reactive, watch } from 'vue'
import { Data } from "@/stores/data";
import { ElementTip } from '@/stores/ElementTip'
import { ElMessage, type FormInstance, type FormRules } from 'element-plus'
import { HTTP } from '@/network/http'
import { Utill } from '@/stores/Utill'
const $emit = defineEmits(["closeReceivePrizePoP","showSoldOutPop", "showLastPop"]);
let def = defineProps({
  LqjlPopType: {
    type: String,
    default: ""
  },
});

interface receiveForm {
  userName: string
  mobileNumber: number
  smsCode: string
  address: string
}

const form = ref<receiveForm>({
  userName: '',
  mobileNumber: null,
  smsCode: '',
  address: '',
})
const ruleFormRef = ref<FormInstance>()
const rules = reactive<FormRules<receiveForm>>({
  userName: [{ required: true, message: '请输入收货人姓名', trigger: 'blur' }],
  mobileNumber: [{ required: true, message: '请输入您的手机号', trigger: 'blur' }],
  smsCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
  address: [{ required: true, message: '请输入您的收货地址', trigger: 'blur' }]
})

const verificationBtnStatus = ref(true)
const btnCountDown = ref(60)
const isShowSmsCodeBtn = ref(true)
let btnTimer = null

function closeReceivePrizePoP() {
  $emit('closeReceivePrizePoP');

}

watch(
  () => form.value.mobileNumber,
  (newValue, oldValue) => {
    console.log(newValue, oldValue)
    const reg = /^1[3456789]\d{9}$/
    verificationBtnStatus.value = !(
      form.value.mobileNumber && reg.test(form.value.mobileNumber.toString())
    )
  }
)

function getSmsCode() {
  HTTP.getSMS(form.value.mobileNumber as any)
    .then((res) => {
      console.log(res)
      if (res.code == 200) {
        console.log(res)
        countDown()
        ElMessage.success('短信验证码已发送，请注意查收')
      }
    })
    .catch((error) => {
      console.error('接口调用出错:', error)
    })
}

function countDown() {
  btnCountDown.value = 60
  isShowSmsCodeBtn.value = false
  btnTimer = setInterval(() => {
    btnCountDown.value--
    if (btnCountDown.value < 0) {
      resetBtn()
    }
  }, 1000)
}

function resetBtn() {
  clearInterval(btnTimer)
  isShowSmsCodeBtn.value = true
}

function gotoAgreeMentPage() {
  Data.isShowAgree.value = true
  Utill.gotoAgreeMentPage()
}


const getVerify = async (formEl: FormInstance | undefined) => {
  console.log(formEl)
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    console.log(form.value, '>>>valid>>', formEl)
    if (valid) {
      HTTP.getVerify(form.value.mobileNumber as any, form.value.smsCode as any)
        .then((res) => {
          console.log(res)
          if (res.code == 200) {
            console.log(res)
            saveAddress()
          }
        })
        .catch((error) => {
          closeReceivePrizePoP()
          ElMessage.error(error.descb)
          console.error('接口调用出错:', error)
        })
    } else {
      console.log('error submit!', fields)
      ElMessage.error('必填项还没完成呢，请检查一下')
    }
  })
}

function showSoldOutPop() {
  console.log('showSoldOutPop')
  $emit('showSoldOutPop',def.LqjlPopType);
  closeReceivePrizePoP()
}

function showLastPop() {
  console.log('showLastPop')
  $emit('showLastPop',def.LqjlPopType);
}

function saveAddress() {
  console.log(form)
  let data = {}
  if(def.LqjlPopType != "xhhy"){
    data = {
      c:5,
      tel:form.value.mobileNumber,
      prize:def.LqjlPopType,
      grade:form.value.userName,
      school:form.value.address,
      openid:Data.userInfo.value?.openid,
      isOver: 1
    }
  }else {
    data = {
      c:5,
      tel:form.value.mobileNumber,
      prize:def.LqjlPopType,
      grade:form.value.userName,
      school:form.value.address,
      openid:Data.userInfo.value?.openid
    }
  }

  // $emit('showLastPop',def.LqjlPopType);
  HTTP.getCollect(data as any)
    .then((data: any) => {
      console.log(data)
      if (data.code == 200 || data.code == '200') {
        console.log(data)
        showLastPop()
        ElementTip.successTip(data.descb)
      } else {
        ElementTip.errorTip(data.descb)
        closeReceivePrizePoP()
      }
    })
    .catch((error) => {
      console.log(error.descb)

      if (error.code == 405 || error.code == '405') {
        //实物奖品库存不足
        if(error.descb.includes('库存不足')){
          showSoldOutPop()
        } else {
          ElementTip.errorTip(error.descb)
          closeReceivePrizePoP()
        }
      }else {
        ElementTip.errorTip(error.descb)
        closeReceivePrizePoP()
      }
    })
}
</script>

<template>
  <div class="maske">
    <div class="address-box">
      <div class="title">填写收货信息</div>
      <div class="address-ctr">
        <el-form :model="form" ref="ruleFormRef" :rules="rules" label-position="top">
          <el-form-item required class="form-item-long" prop="userName">
            <template v-slot:label>
              <span style="z-index: 200">姓名</span>
            </template>
            <el-input v-model="form.userName" :placeholder="'请输入收货人姓名'" type="text" :validate-event="false" />
          </el-form-item>
          <el-form-item required class="form-item-long" prop="mobileNumber">
            <template v-slot:label>
              <span style="z-index: 200">手机号</span>
            </template>
            <el-input v-model="form.mobileNumber" :placeholder="'请输入您的手机号'" :validate-event="false" />
          </el-form-item>
          <el-form-item required class="form-item-long" prop="smsCode">
            <template v-slot:label>
              <span style="z-index: 200">验证码</span>
            </template>
            <div class="verificationCode-ctr">
              <el-input v-model="form.smsCode" style="max-width: 240px" placeholder="请输入验证码"
                :validate-event="false"></el-input>
              <el-button class="verificationCode-btn" :disabled="verificationBtnStatus" v-if="isShowSmsCodeBtn"
                @click="getSmsCode">获取验证码</el-button>
              <div class="count-down" v-else>{{ btnCountDown }}s</div>
            </div>
          </el-form-item>
          <el-form-item required class="form-item-long" prop="address">
            <template v-slot:label>
              <span style="z-index: 200">收货地址</span>
            </template>
            <el-input v-model="form.address" :placeholder="'请输入您的收货地址'" type="text" :validate-event="false" />
          </el-form-item>
          <el-form-item class="form-item-btn">
            <div class="test-tip">
              您提交收货信息即同意
              <span class="footer-link" @click="gotoAgreeMentPage"> 服务协议</span>、
              <span class="footer-link" @click="gotoAgreeMentPage">隐私政策</span>
            </div>

          </el-form-item>
        </el-form>
      </div>
      <div class="fyb-btn ts-btn-ctr">
        <div class="bcj-btn" @click="getVerify(ruleFormRef)">提交收货信息</div>
      </div>
      <div class="close-btn" @click="closeReceivePrizePoP">
        <img src="@/assets/activity/1031/close.png" alt="avatar">
      </div>
    </div>
  </div>
</template>

<style scoped>
.maske {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.address-box {
  width: 409px;
  height: 487px;
  border-radius: 8px;
  background: #ffffff;
  position: relative;
}

.address-ctr {
  margin: 16px 24px 0;
  background: #fff;
  font-size: 16px;

  :deep(.el-form) {
    display: flex;
    flex-wrap: wrap;
    padding: 32px 24px;

    .el-form-item__label {
      justify-content: flex-start;
      position: relative;
    }
  }

  :deep(.el-input) {
    box-shadow: none !important;
    /* 移除阴影 */
    font-size: 14px;
    background: #f6f6fb;

  }

  :deep(.el-input__wrapper) {
    box-shadow: none !important;
    /* 移除阴影 */
    background: #f6f6fb;
    border-radius: 6px;
  }

  :deep(.el-input__inner) {
    font-size: 14px;
  }

  :deep(.el-divider) {
    margin: 12px 0 12px;
    border-color: #E5E6EB;
  }

  :deep(.el-form-item) {
    font-size: 16px;
  }

}

.form-item-long {
  flex-direction: column;
  width: 100%;
  margin-bottom: 12px;

  :deep(.el-form-item__label) {
    justify-content: flex-start;
    position: relative;
  }
}

.verificationCode-ctr {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.verificationCode-btn {
  width: 94px;
  height: 32px;
  border-radius: 69px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #485cec;
  font-size: 14px;
  font-weight: normal;
  color: #485cec;
}

.verificationCode-btn:hover {
  background: #eceefd;
  color: #485cec;
  border: 1px solid #485cec;
}

.fyb-btn {
  display: flex;
  flex-wrap: nowrap;
}

.ts-btn-ctr {
  justify-content: center;
}

.bcj-btn {
  opacity: 1;
  width: 128px;
  height: 40px;
  border-radius: 12px;
  /* 主题色/主题色-1 */
  background: #485CEC;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.bcj-btn:hover {
  background: #3347D6;
}

.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  /*text-align: center;*/
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 0.9);
  /* text-align: right; */
  margin-bottom: 8px;
  height: 58px;
  background: linear-gradient(90deg, #FFEFD2 0%, #FFE8BF 100%);
  border-radius: 8px 8px 0 0;
  padding: 17px 32px;
}

.close-btn {
  width: 80px;
  height: 58px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.close-btn img {
  width: 16px;
  height: 16px;
}

.footer-link {
  color: #485cec;
}

.footer-link:hover {
  cursor: pointer;
}
</style>