<script setup lang="ts">
import { ref } from 'vue'
import { HTTP } from '@/network/http'
let def = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false
  },
  dialogType: {
    type: String,
    default: ''
  },
  dialogTitle: {
    type: String,
    default: ''
  },
})

const orderDialogVisible = ref(def.dialogVisible)
const $emit = defineEmits(['closeDialog'])
const orderList = ref<Array<any>>([])
const loading = ref(true)

init()

function init() {
  getOrderList()
}

function getOrderList() {
  HTTP.getOrderList().then((res) => {
    console.log(res)
    loading.value = false
    if (res.code == 200) {
      console.log(res)
      if (res.data) {
        orderList.value = res.data
      }

    }
  }).catch((error) => {
    loading.value = false
    console.error('接口调用出错:', error)
  })
}
function closeDialog() {
  orderDialogVisible.value = false
  $emit('closeDialog', def.dialogType)
}

</script>

<template>
  <div class="order-dialog">
    <el-dialog v-model="orderDialogVisible" :close-on-click-modal="true" :title="dialogTitle" :show-close="true"
      @close="closeDialog" align-center>
      <div class="order-ctr">
        <div class="list-title">
          <div class="long-item">订单号</div>
          <div class="middle-item">商品类型</div>
          <div class="middle-item">实付数</div>
          <div class="middle-item">支付时间</div>
          <div class="middle-item">订单状态</div>
          <div class="middle-item">支付方式</div>
        </div>
        <div class="list-body" v-if="orderList.length != 0" v-loading="loading">
          <div v-for="item in orderList" :key="item.id" class="list-item">
            <div class="long-item">{{ item?.orderNo }}</div>
            <div class="middle-item">{{ item?.goodsName }}</div>
            <div class="middle-item">￥{{ item?.amount }}</div>
            <div class="middle-item">{{ item?.payTime }}</div>
            <div class="middle-item">{{ item?.status }}</div>
            <div class="middle-item">{{ item?.payTypeName }}</div>
          </div>
        </div>
        <div class="list-null-ctr" v-else>
          <img src="@/assets/vip/order-list-null.png" alt="">
          <p>暂无数据</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">
.order-dialog {
  :deep(.el-dialog) {
    width: 865px;
    height: 579px;
    border-radius: 12px;
    padding: 0;
    background: #F6F6FB;

    .el-dialog__header {
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      align-items: center;
      color: #040a39;
      background: linear-gradient(90deg, #CACFF9 0%, #B3C5FD 100%);
      background: url('@/assets/dialog-header-bg.png') no-repeat;
      background-size: cover;
      -webkit-background-size: cover;
      border-radius: 16px 16px 0 0;
      height: 58px;
      display: flex;
      padding: 0 32px;
    }

    .el-dialog__headerbtn {
      height: 58px;
    }

    .el-dialog__body {
      font-size: 14px;
      font-weight: normal;
      display: flex;
      align-items: center;
      padding: 20px;
    }
  }
}

.order-ctr {
  width: 825px;
  height: 481px;
  border-radius: 12px;
  background: #FFFFFF;
}

.list-title {
  width: 785px;
  height: 40px;
  border-radius: 8px;
  background: #F6F6FB;
  margin-top: 20px;
  margin-left: 20px;
  display: flex;
  font-size: 14px;
  font-weight: normal;
  color: #4E5969;
  line-height: 40px;
}

.long-item {
  width: 210px;
  text-align: center;
  overflow: hidden;
  /*内容超出后隐藏*/
  text-overflow: ellipsis;
  /*超出内容显示为省略号*/
  white-space: nowrap;
  /*文本不进行换行*/
  flex-shrink: 0;
}

.middle-item {
  width: 115px;
  text-align: center;
  overflow: hidden;
  /*内容超出后隐藏*/
  text-overflow: ellipsis;
  /*超出内容显示为省略号*/
  white-space: nowrap;
  /*文本不进行换行*/
  flex-shrink: 0;
}

.list-body {
  height: 421px;
  overflow-y: auto;
  padding: 0 20px 20px;
}

.list-body:hover {
  scrollbar-width: auto;
}

.list-body::-webkit-scrollbar {
  display: block !important;
}

.list-item {
  display: flex;
  font-size: 14px;
  font-weight: normal;
  color: #4E5969;
  line-height: 40px;
}

.list-null-ctr {
  height: 436px;
  text-align: center;
  font-size: 14px;
  font-weight: 350;
  line-height: 23px;
  color: #040A39;
}

.list-null-ctr img {
  width: 95px;
  height: 106px;
  margin-top: 114px;
  margin-bottom: 19px;
}
</style>