import { HTTP } from '../network/http'
import router from '@/router'
import { Data } from '@/stores/data'
import { ElementTip } from '@/stores/ElementTip'
import { hfmath } from '@/components/pptEditor/components/LaTeXEditor/hfmath'
import { nanoid } from 'nanoid'
import katex from 'katex';
import { toSvg } from 'html-to-image';
import { getImageSize } from '@/utils/image'
import emitter from '@/stores/mitt'
export class Utill {


  static getFormatTime(time:number){
    const now = new Date(time);

// 提取时、分、秒
const hours = now.getHours().toString().padStart(2, '0');
const minutes = now.getMinutes().toString().padStart(2, '0');
const seconds = now.getSeconds().toString().padStart(2, '0');

// 格式化为 HH:MM:SS
const currentTime = `${hours}:${minutes}:${seconds}`;
return currentTime;
  }
  //教案生成页面  lessonPlanId  正本教案的id   bookId教材的id
  static gotoNewLessonPage(resourceType:string) {
    // const { href } = router.resolve({
    //   name: 'teachingPlain',
    //   params: { resourceType: resourceType},
    // })
    Data.currShowType.value='no-data'
    Data.currFileId.value=''
    Utill.gotoLessnPage(resourceType);
    // window.open(href, '_blank') //打开新的窗口
  }

static outLoading(){

  Data.isLogin.value = false
  Data.userInfo.value = null
  Data.token.value = ''
  localStorage.removeItem('userInfoToken')
  localStorage.removeItem('userInfo')
  router.push('/');
}
  static gotoOldLessonPage(resourceType:string) {
    Data.currShowType.value='editor'
    // console.log(Data.currShowType.value)
   Utill.gotoLessnPage(resourceType);

    // window.open(href, '_blank') //打开新的窗口
  }
  static gotoLessnPage(resourceType:string='lesson'){
    console.log(">>>222222>>>>>",resourceType)
    router.push({
      path: `/LessonPlan/${resourceType}`
    });
    // router.push('lessonplan');
  }


  //判断字符串里面是否包含链接
  static  isURL(str:string) {
    // 定义链接的正则表达式模式
    const pattern = new RegExp('^(https?:\\/\\/)?' + // 协议部分，可以是 http:// 或 https://
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // 域名部分
      '((\\d{1,3}\\.){3}\\d{1,3}))')  // 或者 IP 地址
    // + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // 端口号和路径部分
    //  '(\\?[;&a-z\\d%_.~+=-]*)?' + // 查询字符串
    //  '(\\#[-a-z\\d_]*)?$', 'i'); // 锚点部分
    // 使用正则表达式模式检查字符串
    return pattern.test(str);
  }

  /**
   * 时间补0
   */
  static addZero(value: number): string {
    return value < 10 ? '0' + value : '' + value;
  }
  static getIcoFromUrl(url:string) {
    let wdIco='wd' 
        const fileExtension = url.split('.').pop(); // 获取文件后缀

        if (!fileExtension) {
            wdIco = 'wd'
            return wdIco;
        }
        switch (fileExtension) {
            case 'ppt':
            case 'pptx':
                wdIco='ppt'
                break;
            case 'doc':
            case 'odt':
            case 'docx':
                wdIco = 'doc'
                break;
            case 'pdf':
                wdIco = 'pdf'
                break;
            case 'txt':
                wdIco = 'txt'
                break;
            case 'jpg': 
            case 'jpeg':
            case 'png':
            case 'gif':
            case 'bmp':
                wdIco = 'tp'
                break;
            case 'xls':
            case 'xlsx':
                wdIco = 'xls'
                break;
            default: wdIco = 'wd'
        }

    return wdIco
}
  /**
   * 格式化日期 默认格式 yyyy-MM-dd hh:mm:ss
   * @param date unit ms | 日期对象
   * @param format
   * @returns
   */
  static formatDate(date: Date | number, format = 'yyyy-MM-dd hh:mm:ss'): string {
    if (!date) {
      return ""

    }

    if (typeof date === 'number'||typeof date === 'string') {
      date = new Date(date);
    }
    const o: Record<string, number> = {
      'y+': date.getFullYear(),
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
    };

    for (const k in o) {
      const reg = new RegExp(`(${k})`);
      const replaceVal = o[k] + '';
      format = format.replace(reg, (s) => {
        if (!s.startsWith('y') && replaceVal.length < s.length) {
          return this.addZero(+replaceVal);
        }

        return replaceVal + '';
      });
    }
    return format;
  }
static pptJsonInit(data:any,theme:any){
for(let i=0;i<data.length;i++){
  let itemData=data[i];
  if(itemData.elements&&itemData.elements.length>0){
    for(let j=0;j<itemData.elements.length;j++){
      let elementsData=itemData.elements[j]
      if(elementsData.type=='text'){
        elementsData.defaultFontName=theme.fontName
        elementsData.defaultColor=theme.fontColor;
      }

    }

  }
  if(!itemData.background){
    itemData.background={
      type: 'solid',
      color: theme.backgroundColor,
    }
  }

}
return data;
}
  static gotoAgreeMentPage() {
    const name='agreementText'
    // router.push({ name: 'AgreementText' });
    const { href } = router.resolve({
      path: '/AgreementText',
    })
    window.open(href, '_blank') //打开新的窗口
  }

  static gotoMemberServicePage() {
    const name='memberService'
    // router.push({ name: 'AgreementText' });
    const { href } = router.resolve({
      path: '/MemberService',
    })
    window.open(href, '_blank') //打开新的窗口
  }

  static getCurrentDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份是从0开始的
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  // static renderLaTeXToSVG2 = (latex: string) => {
  //   const svgContainer = document.createElement('div');
  //   katex.render(latex, svgContainer, {
  //     output: 'html',
  //     throwOnError: false,
  //   });
  
  //   const svgElement = svgContainer.querySelector('svg');
  //   if (svgElement) {
  //     const svgString = svgContainer.innerHTML;
  //     const width = svgElement.getAttribute('width') || '0';
  //     const height = svgElement.getAttribute('height') || '0';
  //     const pathElement = svgElement.querySelector('path');
  //     const path = pathElement ? pathElement.getAttribute('d') : null;
  
  //     return { svgString, width, height, path };
  //   } else {
  //     return { svgString: '', width: '0', height: '0', path: null };
  //   }
  // };
  // static  renderLaTeXToSVG = async (latex: string) => {
  //   return new Promise<{ svgString: string; width: string; height: string; path: string | null }>((resolve) => {
  //     window.MathJax.typesetPromise([latex]).then(() => {
  //       const svgElement = document.querySelector('mjx-container > svg') as SVGSVGElement;
  
  //       if (svgElement) {
  //         const svgString = new XMLSerializer().serializeToString(svgElement);
  //         const width = svgElement.getAttribute('width') || '0';
  //         const height = svgElement.getAttribute('height') || '0';
  //         const pathElement = svgElement.querySelector('path');
  //         const path = pathElement ? pathElement.getAttribute('d') : null;
  
  //         resolve({ svgString, width, height, path });
  //       } else {
  //         resolve({ svgString: '', width: '0', height: '0', path: null });
  //       }
  //     });
  //   });
  // };
  
   static  convertLatexToSvg  (latex: string, scale: number = 0.9) {
    try {

      const svg = katex.renderToString(latex, {
        throwOnError: false,
        displayMode: false,
        output: 'html', // 使用 'html' 作为输出格式
      });

const id='latex_'+nanoid(10);
//
return ` <span id="${id}" class="latex-svg" data-latex="${latex}" >${svg}</span>`;


    } catch (error) {
      return ` <span >${latex}</span>`
      // return latex
    }
   
  }

static async processContent(content: string) {
  console.log(">>content>>>>>",content)
  if (typeof content !== 'string'|| !content.includes('$')) {
   return content;
  }
  const formulaPattern = /\$\$([^$]*?)\$\$|\$([^$]*?)\$/g;
  const matches = [...content.matchAll(formulaPattern)];

  // 创建一个数组，用于存储替换后的内容
  const promises = matches.map(async (match) => {
    const latex = (match[1] || match[2]).replace(/\n/g, ''); // 选择匹配到的 LaTeX 公式内容
    const svg =  Utill.convertLatexToSvg(latex);
    return { match: match[0], svg }; // 返回匹配项和对应的 SVG
  });

  // 等待所有的 Promise 完成
  const results = await Promise.all(promises);

  // 替换内容
  results.forEach(({ match, svg }) => {
    content = content.replace(match, svg);
  });

  return content;
}

//设置上传文件WjGroup
static setDocumentsWjGroup() { 
  
  if(Data.uploadDocumentsData.documentsData.length==0 || Data.uploadDocumentsData.wjGroup==''){
    Data.uploadDocumentsData.wjGroup='wjGroup_' + Data.userInfo.value.openid + '_' + new Date().getTime()
  }
}
//字符串匹配
static parseString(str) {
  if (!str || !str.includes('$')) {
    return str
  }
  const regex = /\$\$?([\s\S]*?)\$\$?/g;

// 使用正则表达式进行替换
let result = str.replace(regex, (match, p1) => {
  return `<span data-w-e-type="formula" data-w-e-is-void data-w-e-is-inline data-value="${p1.trim()}"></span>`;
});
return result;
}
static getBaseURL(){
  const dizUrl = window.location.origin
  if(dizUrl&&import.meta.env.MODE == 'production'){
    return dizUrl+'/'
  }
  return import.meta.env.VITE_BASE_URL
// const baseURL1 = import.meta.env.MODE == 'production' ?dizUrl : 

}

static async checkRemaining() {
    await HTTP.getUserInfo()
      .then((res) => {
        console.log(res.data)
        Data.userInfo.value = res.data
        Data.lv.value = res.data.lv
        const currentDate = Utill.getCurrentDate()
        console.log(currentDate)
        console.log(currentDate > res.data.endTime)
        console.log(res.data.endTime)
        if(res.data.endTime != 0 && res.data.endTime != null){
          Data.isVipExpiration.value = currentDate > res.data.endTime
        }else{
          Data.isVipExpiration.value = true
        }
        console.log(res.data.lastCanCountByUser)
        if (res.data.lv == 0) {
          console.log('新用户')
          Data.lastCanCountByUser.value = res.data.lastCanCountByUser
        }
        console.log(Data.isVipExpiration.value)
        console.log('更新用户信息')
        localStorage.setItem('userInfo', JSON.stringify(res.data))
      })
      .catch(() => {
        ElementTip.errorTip('网络错误，请稍候重试')
      })
  }
  static showLoginDialog(){
    if (!Data.isLogin.value){
      const dialogData = {
        dialogType: 'login',
        show: true,
        dialogTitle: ''
      }
      console.log(dialogData)
      emitter.emit('show-appDialog', dialogData)
      return true;
    }
    return false;
    
  }

  static  getQueryString(keyName:any) {
    const url = window.location.href; //获取url中"?"符后的字串
    console.log(">>window.location.href>>",url)
    const params = url.split('?')[1];
    const paramsObj:any = {};
    if (params) {
      const paramsArr = params.split('&');
      for (let i = 0; i < paramsArr.length; i++) {
        const param = paramsArr[i].split('=');
        paramsObj[param[0]] = param[1];
      }
    }
    if (paramsObj&&paramsObj[keyName]) {
      return paramsObj[keyName]
    }
    return '';
  }
  static generateLetters (num: number): string {
    const letters = String.fromCharCode(65 + num)
    return letters
  }
//后退
  static backPage(){
    if (history.state?.back) {
      router.back()
    } else {
      //3. 否则返回主页面
      router.push('/')
    }
  }

  // 会员到期或者体验次数用完后展示弹窗
  static showMemberOrActivityDialog(){
    // const dialogData = {
    //   show: true,
    //   memberDialogType: 'member-Purchase',
    //   memberDialogTitle: ''
    // }
    // emitter.emit('show-memberDialog', dialogData)

    const dialogData = {
      show: true,
      activityDialogType: 'activity-main',
      activityDialogTitle: "邀请活动"
    }
    emitter.emit('show-actDialog', dialogData)
  }


  static debounce(func: Function, wait: number): Function {
    let timeout: number | null;
    return function(this: any, ...args: any[]) {
      if (timeout !== null) clearTimeout(timeout);
      timeout = window.setTimeout(() => func.apply(this, args), wait);
    };
  }
}
