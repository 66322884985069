<script setup lang="ts">
import { ref } from 'vue'
let isShowHdts1 = ref(true)

const $emit = defineEmits(['closeOpenImgUrl', 'getJl', 'openYqhy'])
let def = defineProps({
  LqjlPopType: {
    type: String,
    default: ''
  },
  isShowHdts: {
    type: Boolean,
    default: true
  },
  isSoldOut: {
    type: Boolean,
    default: false
  }
})
function closeOpenImgUrl() {
  $emit('closeOpenImgUrl')
}
function sxJl() {
  $emit('getJl', def.LqjlPopType)

}

</script>

<template>
  <div class="maske" v-if="!isSoldOut">
    <div class="code-ctr" v-if="!isShowHdts">
      <div class="code-top">
        <img :src="'activity1031/' + LqjlPopType + '-jl.png'" alt="avatar" />
      </div>
      <div class="btn-ctr">
        <div class="btn" @click="closeOpenImgUrl">开心收下</div>
      </div>
      <div class="close-btn" @click="closeOpenImgUrl" v-if="!isShowHdts">
        <img src="@/assets/activity/1031/close.png" alt="avatar" />
      </div>
    </div>
    <div class="fyb-hdts" v-else>
      <div class="title">活动提示</div>
      <div class="tip-text" v-if="LqjlPopType == 'fyb'">
        嗨！亲爱的小鸿用户，到这里您已经<span>可领取翻页笔</span>作为奖励啦！如现在领取就<span>不可领取小蜜蜂</span>了哟
        <br />
        建议您<span>继续参加活动</span>冲击更高奖品
      </div>
      <div class="tip-text" v-if="LqjlPopType == 'kyq'">
        您选择领取<span>小蜜蜂</span>，将<span>无法获得翻页笔</span>，是否还要继续填写收件地址
      </div>
      <div class="fyb-btn ts-btn-ctr" v-if="LqjlPopType == 'kyq'">
        <div class="bcj-btn" @click="closeOpenImgUrl">我再想想</div>
        <div class="jxcj-btn" @click="sxJl">填写地址</div>
      </div>
      <div class="fyb-btn ts-btn-ctr" v-else>
        <div class="bcj-btn" @click="sxJl">填写地址</div>
        <div class="jxcj-btn" @click="closeOpenImgUrl">继续参加活动</div>
      </div>
      <div class="close-btn" @click="closeOpenImgUrl">
        <img src="@/assets/activity/1031/close.png" alt="avatar" />
      </div>
    </div>
  </div>
  <div class="maske" v-else @click="closeOpenImgUrl">
    <div class="code-ctr" v-if="!isShowHdts">
      <div class="code-top">
        <img :src="'activity1031/soldOut-jl.png'" alt="avatar" />
      </div>
      <div class="btn-ctr">
        <div class="btn" @click="closeOpenImgUrl">确定</div>
      </div>
      <div class="close-btn" @click="closeOpenImgUrl" v-if="!isShowHdts">
        <img src="@/assets/activity/1031/close.png" alt="avatar" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.fyb-hdts .ts-btn-ctr {
  justify-content: end;
  gap: 8px;
}

.bcj-btn {
  width: 88px;
  height: 32px;
  border-radius: 92px;
  background: #eceefd;
  color: #040a39;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
}

.bcj-btn:hover {
  background: #d0d5fa;
}

.fyb-hdts .jxcj-btn {
  width: 116px;
  height: 32px;
  border-radius: 77px;
  opacity: 1;
  background: #485cec;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
}

.jxcj-btn:hover {
  background: #3347d6;
}

.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  /*text-align: center;*/
  letter-spacing: 0;
  color: rgba(0, 0, 0, 0.9);
  /* text-align: right; */
  margin-bottom: 8px;
}

.tip-text {
  color: #1d2129;
  white-space: pre-line;
  line-height: 24px;
  margin-bottom: 20px;
}

.tip-text span {
  color: #ff4c54;
}

.fyb-hdts {
  width: 392px;
  background: #fff;
  border-radius: 16px;
  padding: 20px 20px 16px;
  box-shadow: 0 4px 10px 0 rgba(158, 172, 229, 0.3);
  position: relative;
}

.fyb-btn {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.btn.cjmf-btn {
  width: 122px;
}

.btn.sxjs-brn {
  width: 108px;
}

.btn {
  width: 293px;
  line-height: 45px;
  border-radius: 34px;
  background: linear-gradient(270deg, #ffca60 0%, #ff9a03 52%, #ffc85b 100%);
  box-sizing: border-box;
  border: 2px solid #ffd98e;
  margin: 10px auto 16px auto;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.btn-ctr {
  background: #fff;
  padding: 2px 0 4px 0;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
}

.maske {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.close-btn {
  width: 46px;
  height: 46px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.close-btn img {
  width: 16px;
  height: 16px;
}

.code-top {
  width: 100%;
  height: 300px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.code-ctr {
  width: 326px;
  position: relative;
}
</style>
