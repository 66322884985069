import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { Data } from '@/stores/data'

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: '/application',
      component: () => import('../views/HomeView.vue'),
      children: [
        {
          name: 'answers',
          path: 'answers',
          component: () => import('../components/home/AnswersView.vue'),
        },
        {
          name: 'application',
          path: 'application',
          component: () => import('../components/home/ApplicationView.vue'),
        },
        {
          name: 'knowledge',
          path: 'knowledge',
          component: () => import('../components/home/KnowledgeView.vue')
        }
      ]
    },
    {
      
      // path: '/',;
      path: '/LessonPlan/:resourceType',
      name: 'lessonplan',
      meta: { requiresAuth: true }, // 需要登录
      component: () => import('../views/LessonPlan.vue')
    },
    {
      // path: '/',
      path: '/AgreementText',
      name: 'agreementText',
      component: () => import('../about/AgreementText.vue')
    },
    {
      // path: '/',
      path: '/Serve',
      name: 'serve',
      component: () => import('../about/Serve.vue')
    },
    {
      // path: '/',
      path: '/MemberService',
      name: 'memberService',
      component: () => import('../about/MemberService.vue')
    },
  ]
})


router.beforeEach((to, from, next) => {
  const isLessonPlan = to.fullPath.includes('/LessonPlan');
  const isAgreementText = to.fullPath === '/AgreementText';

  Data.isShowAgree.value = isAgreementText ? true : false;

  if (!isLessonPlan) {
    sessionStorage.removeItem('editorData');
    sessionStorage.removeItem('basicSetData');
  }

  if (to.matched.some(record => record.meta.requiresAuth) && !Data.isLogin.value) {
    return next({ path: '/application' });
  }

  next();
});

export default router
