<script setup lang="ts">

import { ref, onMounted, onUnmounted, onBeforeUnmount } from 'vue'
import { Data } from '@/stores/data'
import { HTTP } from '@/network/http'
import emitter from '@/stores/mitt'
import Qrcode from 'qrcode';
import { Utill } from '@/stores/Utill'
import { ArrowLeft, ArrowRight } from '@element-plus/icons-vue'

let def = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false
  },
  dialogType: {
    type: String,
    default: ''
  },
  dialogTitle: {
    type: String,
    default: ''
  }
})
const memberDialogVisible = ref(def.dialogVisible)
const $emit = defineEmits(['closeDialog'])

const AllMembershipContentList = ref<Array<any>>([])
const currentSelectMember = ref<any>({})
const memberBenefitsList = ref<Array<any>>([])

//支付方式
const payType = ref<Array<any>>([
  { type: '1', icon: 'vip/wx-icon.png', name: '微信支付' },
  // {type:'2',icon:'vip/alipay-icon.png',name:'支付宝支付'}
])
const currentPayType = ref('1')

const qrcodeData = ref<any>({})
const isRefreshQrcode = ref(false)
const countDownValue = ref<any>(Date.now() + 15 * 60 * 1000)

const scrollbarList = ref<any>([])

let statusTimerId: number | null = null
const randomTime = ref<number>(0)
let scrollbarTimerId: number | null = null
let intervalId;

// 商品卡片横向滚动
const isCanScrollLeft = ref(false)
const isCanScrollRight = ref(false)
const scrollContainer = ref<HTMLElement | null>(null);
const scrollContent = ref<HTMLElement | null>(null);

const loading = ref(true)
const qrcodeLoading = ref(true)

const startTimer = () => {
  if (statusTimerId) {
    clearTimeout(statusTimerId);
  }
  statusTimerId = window.setTimeout(() => {
    getOrderPayStatus()
    startTimer()
  }, 1000)
}

const getRandomTime = () => {
  return Math.floor(Math.random() * (5 - 2)) + 2
}
const startTimer1 = () => {
  randomTime.value = getRandomTime() * 1000
  if (scrollbarTimerId) {
    clearTimeout(scrollbarTimerId)
  }

  // 设置新的定时器
  scrollbarTimerId = window.setTimeout(() => {
    getCarouselContent()
    startTimer1()
  }, randomTime.value)
}

const updateCountDown = () => {
  if (intervalId) {
    clearInterval(intervalId);
  }
  intervalId = setInterval(updateFormattedTime, 10);
}

onBeforeUnmount(() => {
  if (statusTimerId) {
    clearTimeout(statusTimerId)
  }
  if (scrollbarTimerId) {
    clearTimeout(scrollbarTimerId)
  }
  if (intervalId) {
    clearInterval(intervalId);
  }
});

onUnmounted(() => {
  if (statusTimerId) {
    clearTimeout(statusTimerId)

  }
  if (scrollbarTimerId) {
    clearTimeout(scrollbarTimerId)
  }
  if (intervalId) {
    clearInterval(intervalId);
  }
})

init()
function init() {
  getMembershipContent()
}

function closeDialog() {
  memberDialogVisible.value = false
  if (statusTimerId) {
    clearTimeout(statusTimerId)
    statusTimerId = null
  }
  if (scrollbarTimerId) {
    clearTimeout(scrollbarTimerId)
    scrollbarTimerId = null
  }
  $emit('closeDialog', memberDialogVisible.value)
}

function getMembershipContent() {
  HTTP.getMembershipContent().then((res) => {
    console.log(res)
    if (res.code == 200) {
      console.log(res)
      loading.value = false
      AllMembershipContentList.value = res.data
      currentSelectMember.value = res.data[0]
      memberBenefitsList.value = res.data[0].equityContentJSON
      if (currentSelectMember.value.id != 5) {
        getNativePayQrCode()
        getCarouselContent()
      }
      if (res.data.length > 4) {
        isCanScrollRight.value = true
      }
    }
  }).catch((error) => {
    console.error('接口调用出错:', error)
    closeDialog()
  })
}

function getNativePayQrCode() {
  isRefreshQrcode.value = false
  HTTP.getNativePayQrCode(currentSelectMember.value.id, currentPayType.value).then((res) => {
    console.log(res)
    if (res.code == 200) {
      console.log(res)
      qrcodeData.value = res.data
      if (res.data.payAddr) {
        getQrcode()
        getOrderPayStatus()
      }
      updateCountDown()
      const now = Date.now()
      let expiretime = res.data.dateStr - now
      if (expiretime > 0) {
        countDownValue.value = res.data.dateStr
      } else {
        isRefreshQrcode.value = true
      }
    }
  }).catch((error) => {
    console.error('接口调用出错:', error)
  })
}

function getQrcode() {
  Qrcode.toDataURL(qrcodeData.value.payAddr, (error, url) => {
    qrcodeLoading.value = false
    if (error) {
      console.log(error)
      isRefreshQrcode.value = true
    } else {
      qrcodeData.value.payAddr = url
      console.log(url)
    }
  })
}

function getOrderPayStatus() {
  HTTP.getOrderPayStatus(qrcodeData.value.id).then((res) => {
    console.log(res)
    if (res.code == 200) {
      console.log(res)
      console.log(memberDialogVisible.value)
      if (res.data.orderStatus == 1) {
        //未支付
        if (memberDialogVisible.value) {
          startTimer()
        } else {
          clearTimeout(statusTimerId)
        }
      } else if (res.data.orderStatus == 2) {
        //已支付
        if (statusTimerId !== null) {
          clearTimeout(statusTimerId)
        }
        closeDialog()
        let dialogData = {
          show: true,
          tipDialogType: 'purchase',
          tipDialogTitle: '成功',
          purchaseContent: res.data
        }
        emitter.emit('show-tipDialog', dialogData)
      } else {
        //其他情况
        if (statusTimerId !== null) {
          clearTimeout(statusTimerId)
        }
      }
    }
  }).catch((error) => {
    console.error('接口调用出错:', error)
  })
}

function switchMemberType(item: any) {
  currentSelectMember.value = item
  qrcodeLoading.value = false
  if (item.id != 5) {
    getNativePayQrCode()
    getCarouselContent()
  } else {
    clearTimeout(statusTimerId)
    clearTimeout(scrollbarTimerId)
    clearInterval(intervalId);
  }
}

function switchPayType(type: string) {
  currentPayType.value = type
}

function openActDialog() {
  closeDialog()
  let dialogData = {
    show: true,
    activityDialogType: 'activity-main',
    activityDialogTitle: "邀请活动"
  }
  console.log(dialogData)
  emitter.emit('show-actDialog', dialogData)
}

function getCarouselContent() {
  HTTP.getCarouselContent().then((res) => {
    if (res.code == 200) {
      scrollbarList.value = res.data
      console.log(scrollbarList.value)
    }
  }).catch((error) => {
    console.error('接口调用出错:', error)
  })
  if (memberDialogVisible.value) {
    startTimer1()
  } else {
    clearTimeout(scrollbarTimerId)
  }
}

function gotoMemberServicePage() {
  Utill.gotoMemberServicePage()
}

onMounted(() => {
  const scrollable = document.querySelector('.price-card-ctr')
  console.log(scrollable)
  if (scrollable) {
    handleScroll()
  }
});

const scrollLeft = () => {
  scrollContent.value.scrollLeft -= 175
  console.log(scrollContent.value.scrollLeft)
};

const scrollRight = () => {
  scrollContent.value.scrollLeft += 175
  console.log(scrollContent.value.scrollLeft)
};

const handleScroll = () => {
  // const container = scrollContainer.value;
  const container = scrollContent.value;
  const scrollLeft = container.scrollLeft;
  const scrollWidth = container.scrollWidth;
  const clientWidth = container.clientWidth;
  isCanScrollLeft.value = scrollLeft > 0;
  isCanScrollRight.value = scrollLeft + clientWidth < scrollWidth;
};

const formattedTime = ref('00:00:0');

const updateFormattedTime = () => {
  const now = new Date().getTime();
  const remainingTime = countDownValue.value - now;
  if (remainingTime <= 0) {
    formattedTime.value = '00:00:0';
    isRefreshQrcode.value = true;
    clearInterval(intervalId);
    return;
  }
  const minutes = String(Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
  const seconds = String(Math.floor((remainingTime % (1000 * 60)) / 1000)).padStart(2, '0');
  const milliseconds = String(Math.floor((remainingTime % 1000) / 100)).padStart(1, '0');
  formattedTime.value = `${minutes} : ${seconds} : ${milliseconds}`;
};

</script>

<template>
  <div class="member-dialog">
    <el-dialog v-model="memberDialogVisible" :close-on-click-modal="true" :title="dialogTitle" :show-close="false"
      @close="closeDialog" align-center>
      <template #header="{ titleId, titleClass }">
        <div class="member-header">
          <img :src="
            Data.userInfo.value?.headimgurl
              ? Data.userInfo.value?.headimgurl
              : 'https://static.yipigai.cn/theme/20240722191033_57291.png'
          " alt="" class="user-img" />
          <p class="user-name">
            {{ Data.userInfo.value?.nickname ? Data.userInfo.value?.nickname : '微信用户' }}
          </p>
          <img v-if="Data.isVipExpiration.value" src="@/assets/vip/vip-tip-grey.png" alt="" class="vip-tip-img" />
          <img v-if="Data.userInfo.value?.endTime && !Data.isVipExpiration.value" src="@/assets/vip/vip-tip-yellow.png"
            alt="" class="vip-tip-img" />
          <p v-if="!Data.userInfo.value?.endTime && Data.isVipExpiration.value" class="vip-tip-text">会员权益尚未开通</p>
          <p v-if="Data.userInfo.value?.endTime && Data.isVipExpiration.value" class="vip-tip-text">您的会员已到期，赶紧续费吧</p>
          <span :id="titleId" :class="titleClass" class="head-title">{{ dialogTitle }}</span>
          <div @click="closeDialog" class="close-btn" style="">
            <img style="width: 16px; height: 16px" src="@/assets/activity/act-close-icon.png" alt="" />
          </div>
        </div>
      </template>
      <div class="dialog-body" v-loading="loading">
        <div class="member-benefits">
          <div class="benefits-header">
            <p class="header-main-title">会员专属权益</p>
            <p class="header-subtitle">开通可畅享多种会员权益</p>
          </div>
          <div class="benefits-List">
            <div v-for="(item, index) in memberBenefitsList" :key="index" class="benefits-item"
              :class="index == 0 ? 'first-benefit' : ''">
              <div class="item-icon">
                <img :src="item.icon" alt="">
              </div>
              <div>
                <p class="benefits-main-title">{{ item?.main_name }}</p>
                <p class="benefits-subtitle">{{ item?.next_name }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="member-details">
          <div class="member-details-list" ref="scrollContainer">
            <div class="list-arrow-left" v-show="isCanScrollLeft" @click.stop="scrollLeft">
              <el-icon>
                <ArrowLeft />
              </el-icon>
            </div>
            <div class="price-card-ctr" ref="scrollContent" @scroll="handleScroll">
              <div class="price-card" v-for="item in AllMembershipContentList" :key="item.id"
                @click="switchMemberType(item)" :class="currentSelectMember.id == item.id ? 'selected-member' : ''">
                <img v-show="item.label == 1" class="left-sign" src="@/assets/vip/card-left-sign.png" alt="">
                <div class="card-title">
                  {{ item.id != 5 ? item?.name : '邀请新用户' }}
                </div>
                <div class="card-price">
                  <span v-show="item.id != 5">￥</span>
                  <span class="price-number">{{ item.id != 5 ? item?.amountStr : '1' }}</span>
                  <span class="price-unit" v-show="item.id == 5">人</span>
                  <span class="price-unit">/{{ item?.unit }}</span>
                </div>
                <div class="card-line"></div>
                <div class="original-price">￥{{ item?.originalPriceStr }}</div>
                <div class="member-select-sign" v-show="currentSelectMember.id == item.id">
                  <img class="pay-type-icon" src="@/assets/vip/member-select-icon.png" alt="">
                </div>
              </div>
            </div>
            <div class="list-arrow-right" v-show="isCanScrollRight" @click.stop="scrollRight">
              <el-icon>
                <ArrowRight />
              </el-icon>
            </div>
          </div>
          <div class="explain-content" v-html="currentSelectMember?.explainContent"></div>
          <div class="act-content" v-if="currentSelectMember?.id == 5">
            <div class="pay-card-line" style="margin-left: 26px"></div>
            <div class="act-card">
              <img class="act-left-img" src="@/assets/vip/vip-act-left.png" alt="">
              <img class="act-center-img" src="@/assets/vip/vip-act-center.png" alt="">
              <div class="act-link-btn" @click="openActDialog">
                立即邀请
                <img class="btn-left-img" src="@/assets/vip/act-btn-left.png" alt="">
                <img class="btn-right-img" src="@/assets/vip/act-btn-right.png" alt="">
              </div>
            </div>
          </div>
          <div class="pay-card" v-if="currentSelectMember?.id != 5 && !loading">
            <div class="pay-content">
              <div class="pay-qrcode" v-loading="!qrcodeData.payAddr || qrcodeData.payAddr.includes('weixin')">
                <img v-show="qrcodeData.payAddr && !qrcodeData.payAddr.includes('weixin')" class="qrcode-img"
                  :src="qrcodeData.payAddr" alt="">
                <img v-show="isRefreshQrcode" @click="getNativePayQrCode" class="refresh-btn"
                  src="@/assets/vip/refresh-btn.png" alt="">
              </div>
              <div>
                <div class="pay-type">
                  <div class="pay-type-btn" v-for="item in payType"
                    :class="currentPayType == item.type ? 'selected-type' : ''" :key="item.type"
                    @click="switchPayType(item.type)">
                    <img class="pay-type-icon" :src="item.icon" alt="">
                    {{ item.name }}
                    <div class="select-sign" v-show="currentPayType == item.type">
                      <img class="pay-type-icon" src="@/assets/vip/pay-select-icon.png" alt="">
                    </div>
                  </div>
                </div>
                <div class="pay-time">
                  <span>支付剩余时间：</span>
                  <div @change="updateFormattedTime">
                    <div>{{ formattedTime }}</div>
                  </div>
                </div>
                <div class="pay-number-content">
                  实付：<span class="pay-unit">￥</span><span class="pay-number">{{ currentSelectMember?.amountStr }}</span>
                </div>
                <div class="pay-tip">
                  开通前请阅读
                  <span class="agreement-link" @click="gotoMemberServicePage">《小鸿助教会员服务协议》</span>
                </div>
              </div>
            </div>
            <div class="pay-card-line"></div>
            <div class="scrollBar-ctr">
              <el-carousel height="22px" direction="vertical" indicator-position="none" :interval="1000" :autoplay="true"
                v-if="scrollbarList[0] != null">
                <el-carousel-item v-for="(item, index) in scrollbarList" :key="index">
                  <div class="carousel-text">
                    {{ item }}
                  </div>
                </el-carousel-item>
              </el-carousel>

            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">
.member-dialog {
  :deep(.el-dialog) {
    width: 961px;
    height: 595px;
    border-radius: 12px;
    background: linear-gradient(90deg, #FEF9EC 0%, #B5BDF2 100%);
    padding: 0;
    position: relative;
    z-index: 100;

    .el-dialog__header {
      align-items: center;
      color: #040a39;
      border-radius: 16px 16px 0 0;
      padding: 0;
    }

    .el-dialog__body {
      height: 527px;
      font-size: 14px;
      font-weight: normal;
      display: flex;
      align-items: center;
      background: #ffffff;
      border-radius: 0 0 12px 12px;
    }
  }
}

.dialog-body {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.member-header {
  display: flex;
  padding: 0 32px;
  height: 68px;
  align-items: center;
  position: relative;
}

.user-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 13px;
}

.user-name {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #040A39;
}

.vip-tip-img {
  margin-left: 4px;
  margin-right: 6px;
  width: 75px;
}

.vip-tip-text {
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #8990C5;
}

.close-btn {
  width: 64px;
  height: 68px;
  position: absolute;
  right: 0;
  padding: 26px 24px;
  cursor: pointer;
}

.member-benefits {
  width: 207px;
  height: 487px;
  background: #F7F8FD;
  margin-left: 16px;
  margin-top: 19px;
  margin-bottom: 21px;
  border-radius: 12px;
  overflow-y: auto;
  padding-top: 16px;

}

.benefits-header {
  padding-left: 38px;
}

.header-main-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #040A39;
  margin-bottom: 6px;
  margin-left: 18px;

}

.header-subtitle {
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  color: #8990C5;
}

.benefits-main-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #040A39;
  margin-bottom: 4px;

}

.benefits-subtitle {
  font-size: 11px;
  font-weight: normal;
  line-height: 16px;
  color: #8990C5;
}

.benefits-List {
  overflow-y: auto;
  //height: 403px;
  height: calc(100% - 46px);
  padding-bottom: 22px;
}

.benefits-item {
  display: flex;
  padding-left: 16px;
  margin-top: 28px;
}

.first-benefit {
  margin-top: 23px;
}

.item-icon {
  width: 40px;
  height: 40px;
  //background: #E3E7FF;
  //border-radius: 50%;
  margin-right: 8px;
}

.member-details {
  width: calc(100% - 223px);
  overflow-x: hidden;
}

.member-details-list {
  display: flex;
  position: relative;
  overflow: hidden;
  //overflow-x: auto;
  //scrollbar-width: none; /* Firefox */
  //-ms-overflow-style: none;  /* Internet Explorer 10+ */
  //user-select: none;
}

//.member-details-list::-webkit-scrollbar {
//  display: none !important;
//}

.list-arrow-left,
.list-arrow-right {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #FFFFFF;
  position: absolute;
  top: 118px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 6px 0px rgba(211, 213, 236, 0.62);
  cursor: pointer;
  user-select: none;
}

.list-arrow-left {
  left: 13px;
}

.list-arrow-left:hover {
  background: #D9DCFF;
  box-shadow: 0px 1px 6px 0px rgba(211, 213, 236, 0.62);
}


.list-arrow-right {
  right: 13px;
}

.list-arrow-right:hover {
  background: #D9DCFF;
  box-shadow: 0px 1px 6px 0px rgba(211, 213, 236, 0.62);
}

.price-card-ctr {
  display: flex;
  /*width: max-content;*/
  will-change: transform;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  padding-left: 24px;
  user-select: none;
}

.price-card-ctr::-webkit-scrollbar {
  display: none !important;
}

.price-card {
  width: 160px;
  min-width: 160px;
  height: 173px;
  border: 3px solid #D3D5EC;
  border-radius: 12px;
  margin-top: 44px;
  margin-right: 16px;
  flex-shrink: 0;
  text-align: center;
  margin-bottom: 14px;
  position: relative;
}

.selected-member {
  height: 183px;
  background: linear-gradient(180deg, rgba(69, 61, 238, 0.2) 0%, rgba(69, 61, 238, 0.03) 69%);
  box-sizing: border-box;
  border: 3px solid #453DEE;
  margin-top: 38px;
  margin-bottom: 5px;
}

.left-sign {
  width: 82px;
  height: 26px;
  position: absolute;
  left: -3px;
  top: -13px;
}

.card-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #040A39;
  margin-top: 29px;
  margin-bottom: 13px;
}

.card-price {
  font-size: 24px;
  font-weight: 700;
  color: #040A39;
  font-family: Alimama ShuHeiTi;
  margin-bottom: 10px;
}

.price-number {
  font-size: 36px;
}

.card-line {
  width: 128px;
  height: 1px;
  background: #E6E6FA;
  margin: auto auto 12px;
}

.original-price {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #8990C5;
  text-decoration: line-through;
}

.member-select-sign {
  width: 32px;
  height: 32px;
  position: absolute;
  right: -1px;
  bottom: 0;
  background: url("@/assets/vip/member-select-sign.png")no-repeat;
  background-size: cover;
  display: flex;
  align-items: end;
  justify-content: end;
}

.member-select-sign img {
  width: 16px;
  height: 16px;
  margin: 0 1px 2px 0;
}

.explain-content {
  font-size: 13px;
  font-weight: normal;
  color: #8990C5;
  line-height: 19px;
  margin-left: 30px;
}

.explain-content span {
  color: #453DEE
}

.act-card {
  margin-left: 20px;
  margin-top: 14px;
  width: 681px;
  height: 160px;
  border-radius: 18px;
  background: linear-gradient(90deg, #F7F8FD 0%, #D5DCFF 99%);
  position: relative;
  z-index: 100;
  display: flex;
}

.act-left-img {
  position: absolute;
  left: -6px;
  top: 8px;
  width: 173px;
  height: 153px;
}

.act-center-img {
  width: 168px;
  height: 88px;
  margin-left: 173px;
  margin-top: 36px;
}

.act-link-btn {
  width: 264px;
  height: 42px;
  border-radius: 69px;
  background: #453DEE;
  font-size: 16px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
  color: #FFFFFF;
  margin-left: 26px;
  margin-top: 86px;
  position: relative;
  cursor: pointer;
}

.act-link-btn:hover {
  border: none !important;
}

.btn-left-img {
  position: absolute;
  left: 8px;
  top: -18px;
  width: 83px;
  height: 23px;
  z-index: -1;
}

.btn-right-img {
  width: 71px;
  height: 71px;
  position: absolute;
  right: 0;
  top: -55px;
  z-index: -1;
}

.pay-card {
  width: 690px;
  height: 244px;
  border-radius: 12px;
  background: #F7F8FD;
  margin-left: 24px;
  margin-top: 12px;
  padding-top: 16px;
  padding-left: 16px;
}

.pay-content {
  display: flex;
}

.pay-qrcode {
  width: 178px;
  height: 178px;
  //background: #FFFFFF;
  box-sizing: border-box;
  position: relative;


}

.qrcode-img {
  width: 178px;
  height: 178px;
  border: 1px solid #D3D5EC;
  border-radius: 12px;
}


.refresh-btn {
  width: 160px;
  height: 160px;
  position: absolute;
  right: 9px;
  bottom: 9px;
  cursor: pointer;
}

.pay-type {
  display: flex;
  margin-left: 11px;
}

.pay-type-btn {
  width: 150px;
  height: 38px;
  background: #FFFFFF;

  box-sizing: border-box;
  border: 2px solid #D3D5EC;
  border-radius: 8px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  color: #040A39;
  cursor: pointer;
  position: relative;
}

.pay-type-btn:hover {
  border: 2px solid #453DEE;
}

.pay-type-icon {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.selected-type {
  border: 2px solid #453DEE;
}

.select-sign {
  width: 21px;
  height: 21px;
  position: absolute;
  right: -1px;
  bottom: -1px;
  background: url("@/assets/vip/pay-select-sign.png")no-repeat;
  background-size: cover;
  display: flex;
  align-items: end;
  justify-content: end;
}

.select-sign img {
  width: 12px;
  height: 12px;
  margin: 0;
  margin-bottom: 1px;
}

.pay-time {
  width: 199px;
  height: 36px;
  background: url("@/assets/vip/pay-time-bg.png")no-repeat;
  background-size: cover;
  margin-left: 9px;
  margin-top: 13px;
  margin-bottom: 6px;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  color: #FF3256;

  :deep(.el-statistic__content) {
    color: #FF3256;
    font-size: 14px;
    font-weight: 500;
    line-height: 36px;
  }
}

.pay-time span {
  margin-left: 14px;
}

.pay-number-content {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  color: #040A39;
  margin-left: 11px;
}

.pay-unit {
  font-size: 24px;
  font-weight: bold;
  line-height: 40px;
  color: #FF3256;
  font-family: Alimama ShuHeiTi;
}

.pay-number {
  font-family: Alimama ShuHeiTi;
  font-size: 36px;
  font-weight: bold;
  line-height: 40px;
  color: #FF3256;
}

.pay-tip {
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  margin-left: 11px;
  margin-top: 8px;
  color: #8990C5;
}

.agreement-link {
  color: #453DEE;
  cursor: pointer;
}

.pay-card-line {
  width: 658px;
  height: 1px;
  background: #E6E6FA;
  margin-top: 12px;
  margin-bottom: 4px;
}

.scrollBar-ctr {
  width: 600px;
  height: 24px;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 22px;
}

.carousel-text {
  width: 600px;
  height: 24px;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  color: #8990C5;
}</style>