<script setup lang="ts">
import { ref, watch, reactive, onMounted, nextTick, onBeforeUnmount } from 'vue'

let def = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false
  },
  reportDialogData: {
    type: Object,
    default: {}
  }
})

const treeTransferVisible = ref(def.dialogVisible)
const $emit = defineEmits(['closeDialog'])
const reportHeadList = ref<any[]>([
  {
    id: 1,
    name: '试题数量',
    background: 'icons/questions/report-num-bg.png',
    icon: 'icons/questions/report-num-icon.png'
  },
  {
    id: 2,
    name: '主要考察知识点',
    background: 'icons/questions/report-knowledge-bg.png',
    icon: 'icons/questions/report-knowledge-icon.png'
  },
  {
    id: 3,
    name: '难易程度',
    background: 'icons/questions/report-difficulty-bg.png',
    icon: 'icons/questions/report-difficulty-icon.png'
  }
])
let reportData = def.reportDialogData.otherData ? def.reportDialogData.otherData : []
const questionsNum = reportData.length
let reportStructuringData = { '1': [], '2': [], '3': [] }
let mainKnowledge = ref<string>('')
const mainDifficulty = ref<any>('')
const mainKnowledgeRef = ref<HTMLElement | null>(null)
const knowledgeOverflow = ref(true)

const reportTypeList = [
  {
    id: '1',
    type: 'KnowledgePoints',
    name: '知识点统计',
    tableTitle: [
      { id: '1', title: 'serialNumber', name: '序号', width: 60 },
      { id: '2', title: 'knowledgePoints', name: '知识点', width: 150 },
      { id: '3', title: 'proportion', name: '占比', width: 80 },
      { id: '4', title: 'questionsQuantity', name: '题号', width: 160 }
    ]
  },
  {
    id: '2',
    type: 'breakdownTable',
    name: '作业细目表',
    tableTitle: [
      { id: '1', title: 'questionNumber', name: '题号', width: 70 },
      { id: '2', title: 'questionType', name: '题型', width: 100 },
      { id: '3', title: 'knowledgePoints', name: '知识点', width: 210 },
      { id: '4', title: 'difficulty', name: '难度', width: 70 }
    ]
  },
  {
    id: '3',
    type: 'difficultyStatistics',
    name: '难易度统计',
    tableTitle: [
      { id: '1', title: 'difficulty', name: '难度', width: 80 },
      { id: '2', title: 'questionNumber', name: '题号', width: 450 },
      { id: '3', title: 'proportion', name: '题量占比', width: 80 }
    ]
  }
]

const currentReportType = ref<any>(reportTypeList[0])

const difficultyList = {
  "容易": 'border: 1px solid #A7EA86;background: #EAF7E3;color: #67C23A;',
  "较易": 'border: 1px solid #65CA33;background: #D3F6F0;color: #00C4BD;',
  "普通": 'border: 1px solid #95C5FF;background: #EEF6FD;color: #1E83FF;',
  "较难": 'border: 1px solid #FFD08B;background: #FFEDD4;color: #FF8C00;',
  "困难": 'border: 1px solid rgba(245, 108, 108, 0.2);background: #FCD3D3;color: #FF4A4A;'
}



const keyPointDetails: Record<string, { count: number; questionsQuantity: string[] }> = {}
let questionDetails: {
  questionNumber: string
  questionType: string
  knowledgePoints: string
  difficulty: string
}[] = []
const difficultLevelDetails: Record<string, { count: number; questionsQuantity: string[] }> = {}
calculateKeyPointStats(reportData)

function calculateKeyPointStats(arr: any[]) {
  arr.forEach((question, index) => {
    const mainQuestionNumber = (index + 1).toString()
    const childQuestions = question.Content?.ChildQuestionList
    if (childQuestions?.length) {
      childQuestions.forEach((childQuestion, childIndex) => {
        const childQuestionNumber = `${mainQuestionNumber}(${childIndex + 1})`
        childQuestion.KeyPointList.forEach((keyPoint) => {
          if (!keyPointDetails[keyPoint]) {
            keyPointDetails[keyPoint] = { count: 0, questionsQuantity: [] }
          }
          keyPointDetails[keyPoint].count++
          keyPointDetails[keyPoint].questionsQuantity.push(childQuestionNumber)
        })

        const difficultLevel = childQuestion.DifficultLevel
        if (!difficultLevelDetails[difficultLevel]) {
          difficultLevelDetails[difficultLevel] = { count: 0, questionsQuantity: [] }
        }
        difficultLevelDetails[difficultLevel].count++
        difficultLevelDetails[difficultLevel].questionsQuantity.push(childQuestionNumber)

        questionDetails.push({
          questionNumber: childQuestionNumber,
          questionType: childQuestion.ExerciseTypeAliasName,
          knowledgePoints: childQuestion.KeyPointList.join(', '),
          difficulty: childQuestion.DifficultLevel
        })
      })
    } else {
      question.KeyPointList.forEach((keyPoint) => {
        if (!keyPointDetails[keyPoint]) {
          keyPointDetails[keyPoint] = { count: 0, questionsQuantity: [] }
        }
        keyPointDetails[keyPoint].count++
        keyPointDetails[keyPoint].questionsQuantity.push(mainQuestionNumber)
      })
      const difficultLevel = question.DifficultLevel
      if (!difficultLevelDetails[difficultLevel]) {
        difficultLevelDetails[difficultLevel] = { count: 0, questionsQuantity: [] }
      }
      difficultLevelDetails[difficultLevel].count++
      difficultLevelDetails[difficultLevel].questionsQuantity.push(mainQuestionNumber)

      questionDetails.push({
        questionNumber: mainQuestionNumber,
        questionType: question.ExerciseTypeAliasName,
        knowledgePoints: question.KeyPointList.join(', '),
        difficulty: question.DifficultLevel
      })
    }
  })
  const totalKeyPoints = Object.values(keyPointDetails).reduce(
    (sum, detail) => sum + detail.count,
    0
  )
  const result = Object.entries(keyPointDetails)
    .map(([keyPoint, detail]) => ({
      knowledgePoints: keyPoint,
      percentage: Math.round((detail.count / totalKeyPoints) * 100),
      proportion: Math.round((detail.count / totalKeyPoints) * 100) + '%',
      questionsQuantity: detail.questionsQuantity.join(', ')
    }))
    .sort((a, b) => b.percentage - a.percentage)
    .map((item, index) => ({
      ...item,
      serialNumber: index + 1
    }))
  if (result && result.length > 0) {
    reportStructuringData['1'] = result
    mainKnowledge.value = result[0].knowledgePoints
  }
  reportStructuringData['2'] = questionDetails

  const totalQuestions = Object.values(difficultLevelDetails).reduce(
    (sum, detail) => sum + detail.count,
    0
  )
  const difficultLevelResult = Object.entries(difficultLevelDetails)
    .map(([difficultLevel, detail]) => ({
      difficulty: difficultLevel,
      questionNumber: detail.questionsQuantity.join(', '),
      proportion: Math.round((detail.count / totalQuestions) * 100) + '%',
      percentage: Math.round((detail.count / totalQuestions) * 100)
    }))
    .sort((a, b) => b.percentage - a.percentage)
  if (difficultLevelResult && difficultLevelResult.length > 0) {
    reportStructuringData['3'] = difficultLevelResult
    mainDifficulty.value = difficultLevelResult[0].difficulty
  }
}

function closeDialog() {
  treeTransferVisible.value = false
  $emit('closeDialog')
}
onMounted(async () => {
  await nextTick()
  checkOverflow()
  window.addEventListener('resize', checkOverflow)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', checkOverflow)
})
const checkOverflow = () => {
  console.log('mainKnowledgeRef.value', mainKnowledgeRef.value)
  if (mainKnowledgeRef.value) {
    console.log('mainKnowledgeRef.value', mainKnowledgeRef.value[0].scrollWidth)
    knowledgeOverflow.value =
      mainKnowledgeRef.value[0].scrollWidth > mainKnowledgeRef.value[0].clientWidth
    console.log('mainKnowledgeRef.value', knowledgeOverflow.value)
  }
}
function switchReportType(item: any) {
  currentReportType.value = item
}

</script>

<template>
  <div class="report-dialog">
    <el-dialog v-model="treeTransferVisible" :close-on-click-modal="false" :title="reportDialogData.reportDialogTitle"
      :show-close="true" @close="closeDialog" align-center>
      <div class="report-head">
        <div class="report-head-item" v-for="item in reportHeadList" :key="item.id"
          :style="{ backgroundImage: `url(${item.background})` }">
          <div class="head-content">
            <p class="head-title">{{ item.name }}</p>
            <p v-if="item.id == 1" class="head-question">
              <span class="head-question-num">{{ questionsNum }}</span> 题
            </p>
            <el-tooltip v-else-if="item.id == 2" effect="light" :content="mainKnowledge" placement="bottom"
              :disabled="!knowledgeOverflow">
              <p ref="mainKnowledgeRef" class="head-data">{{ mainKnowledge }}</p>
            </el-tooltip>
            <p v-else-if="item.id == 3" class="head-data">{{ mainDifficulty }}</p>
          </div>
          <img class="head-icon" :src="item.icon" alt="" />
        </div>
      </div>
      <div class="report-ctr">
        <div class="report-type">
          <div class="type-item" v-for="item in reportTypeList" :key="item.id" @click="switchReportType(item)"
            :class="currentReportType.type == item.type ? 'select-item' : ''">
            <span>{{ item.name }}</span>
            <div v-show="currentReportType.type == item.type" class="selected-line"></div>
          </div>
        </div>
        <div class="table-ctr">
          <el-table :data="reportStructuringData[currentReportType.id]" style="width: 100%"
            :header-cell-style="{ backgroundColor: '#F6F6FB' }">
            <el-table-column v-for="column in currentReportType.tableTitle" :key="column.title" :prop="column.title"
              :label="column.name" :min-width="column.width" :fixed="false" :fit="true">
              <template v-slot="{ row }" v-if="column.title == 'difficulty'">
                <div class="difficulty-ctr"
                  :style="difficultyList[row[column.title]] ? difficultyList[row[column.title]] : ''">
                  {{ row[column.title] }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">
.report-dialog {
  :deep(.el-dialog) {
    width: 787px;
    height: 83%;
    min-height: 498px;
    max-height: 747px;
    border-radius: 12px;
    padding: 0;
    background: #f6f6fb;

    .el-dialog__header {
      font-size: 18px;
      font-weight: 600;
      line-height: 26px;
      align-items: center;
      color: #040a39;
      background: linear-gradient(90deg,
          #cacff9 0%,
          #b3c5fd 100%); //背景图片无法显示/加载过慢时备用背景色
      background: url('@/assets/dialog-header-bg.png') no-repeat;
      background-size: cover;
      -webkit-background-size: cover;
      border-radius: 16px 16px 0 0;
      height: 58px;
      display: flex;
      padding: 0 32px;
    }

    .el-dialog__headerbtn {
      height: 58px;
    }

    .el-dialog__headerbtn .el-dialog__close {
      color: #040a39;
    }

    .el-dialog__headerbtn .el-dialog__close:hover {
      color: #485cec;
    }

    .el-dialog__body {
      font-size: 14px;
      font-weight: normal;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 20px 24px 24px;
      height: calc(100% - 102px);
      background: #ffffff;
      border-radius: 4px;
    }
  }
}

.report-head {
  display: flex;
  //gap: 28px;
  margin-top: 20px;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
}

.report-head-item {
  width: 210px;
  height: 80px;
  border-radius: 8px;
  display: flex;
  position: relative;
  background-size: cover;
  -webkit-background-size: cover;
}

.head-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
  padding-bottom: 16px;
  padding-top: 12px;
}

.head-title {
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  color: #1D2129;
}

.head-question-num {
  font-weight: 600;
  font-size: 24px;
}

.head-question {
  font-size: 16px;
  font-weight: 600;
  font-family: MiSans;
  color: #040A39;
}

.head-data {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #040A39;
  max-width: 132px;
  overflow: hidden;
  /*内容超出后隐藏*/
  text-overflow: ellipsis;
  /*超出内容显示为省略号*/
  white-space: nowrap;
  /*文本不进行换行*/
}

.head-icon {
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.report-ctr {
  width: 100%;
  height: calc(100% - 100px);
}

.report-type {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-left: 8px;
}

.type-item {
  padding: 16px 17px;
  position: relative;
  cursor: pointer;
  color: #4e5969;
}

.select-item {
  color: #485cec;
}

.selected-line {
  background: #6f56ff;
  border-radius: 17px;
  width: 28px;
  height: 4px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.table-ctr {
  width: 100%;
  padding: 0 20px 20px;
  height: calc(100% - 56px);

  :deep(.el-table) {
    --el-table-border: none;
    --el-table-border-color: none;
    border: none;
    height: 100%;

    .el-table__header th:first-child{
      border-top-left-radius: 8px;
    }
    .el-table__header th:last-child{
      border-top-right-radius: 8px;
    }

    .el-table__header .cell {
      padding: 0 0 0 12px;
    }

    td.el-table__cell {
      border-width: 0 0 1px 1px;
      border-style: solid;
      border-color: #ebeef5;
      vertical-align: top;
      box-sizing: border-box;
    }

    .el-table__body td:last-child {
      border-right: 1px solid #ebeef5;
    }

    .el-table__body tr:last-child td:first-child{
      border-bottom-left-radius: 8px;
    }
    .el-table__body tr:last-child td:last-child{
      border-bottom-right-radius: 8px;
    }
  }
}

.difficulty-ctr {
  width: 40px;
  font-size: 12px;
  font-weight: normal;
  height: 22px;
  line-height: 20px;
  border-radius: 4px;
  text-align: center;
  border: 1px solid rgb(167, 234, 134);
  background: rgb(234, 247, 227);
  color: rgb(103, 194, 58);
}
</style>

<style>
.table-ctr .el-scrollbar {
  --el-scrollbar-opacity: 0.3 !important;
}
</style>
